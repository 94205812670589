:root {
  --gutter: 25px;
  --card-padding: 25px;
  --mobile-break-point: 600px;
  --min-col-width: 60px;
  --shadow-sm: 0 1px 2px 0 #0000000d;
  --shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --shadow-md: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --shadow-lg: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --shadow-xl: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --shadow-2xl: 0 25px 50px -12px #00000040;
  --shadow-inner: inset 0 2px 4px 0 #0000000d;
  --shadow-none: 0 0 #0000;
  --color-50: #f8fafc;
  --color-100: #f1f5f9;
  --color-200: #e2e8f0;
  --color-300: #cbd5e1;
  --color-400: #94a3b8;
  --color-500: #64748b;
  --color-600: #475569;
  --color-700: #334155;
  --color-800: #1e293b;
  --color-900: #0f172a;
  --color-bg: white;
  --color-card-bg: white;
  --color-success: #50cd89;
  --color-error: #f1416c;
  --color-warning: #f19641;
  --color-info: #3e97ff;
  --bg-success: #f6fff6;
  --bg-error: #fff5f8;
  --bg-warning: #fffdf5;
  --bg-info: #eef6ff;
  --brand-color: #006eff;
  --heading-color: var(--color-800);
  --body-text-color: var(--color-600);
  --font-family: Arial, Helvetica, sans-serif;
  --text-md: .875rem;
  --text-sm: .8125rem;
  --text-xs: .625rem;
  --form-element-color: #ddd;
  --form-element-padding: .625rem;
  --card-border-radius: 12px;
  --btn-border-radius: 6px;
  --form-element-border-radius: 6px;
  --table-cell-padding: 10px 10px 10px 0px;
}

body {
  font-family: var(--font-family);
  font-size: var(--text-md);
  color: var(--body-text-color);
  background-color: var(--color-bg);
  margin: 0;
}

* {
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: var(--color-light-3);
  opacity: 1;
}

::placeholder {
  color: var(--color-light-3);
  opacity: 1;
}

input {
  color: var(--body-text-color);
  font-family: var(--font-family);
}

.underline {
  text-decoration: underline;
}

.nowrap {
  text-wrap: nowrap;
}

.text-sm {
  font-size: var(--text-sm);
}

.text-xs {
  font-size: var(--text-xs);
}

.text-align-center {
  text-align: center;
}

.color-light-1 {
  color: var(--color-light-1);
}

.color-light-2 {
  color: var(--color-light-2);
}

.color-light-3 {
  color: var(--color-light-3);
}

.card-padding, .cc-flexbox-padded {
  padding: var(--card-padding);
}

.c-chart {
  margin: 0 auto;
}

.c-chart-empty {
  text-align: center;
  padding: 25px;
}

.c-checkbox-container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  display: flex;
  position: relative;
}

.c-checkbox {
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
}

.c-checkbox-checkmark {
  border-radius: var(--form-element-border-radius);
  background-color: #eee;
  flex-shrink: 0;
  width: 19px;
  height: 19px;
  position: relative;
  top: 0;
  left: 0;
}

.c-checkbox-container input:checked ~ .c-checkbox-checkmark {
  background-color: var(--brand-color);
}

.c-checkbox-checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.c-checkbox-container input:checked ~ .c-checkbox-checkmark:after {
  display: block;
}

.c-checkbox-container .c-checkbox-checkmark:after {
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  width: 4px;
  height: 8px;
  top: 2px;
  left: 6px;
  transform: rotate(45deg);
}

.c-checkbox-disabled .c-checkbox-container input:checked ~ .c-checkbox-checkmark {
  background-color: #eee;
}

.c-checkbox-disabled .c-checkbox-label {
  color: #ccc;
}

.cc-flexbox, .cd-flexbox {
  min-width: 0;
  display: flex;
}

.cc-layout-grid-container {
  margin: calc(var(--gutter) * -.5);
  flex-wrap: wrap;
  display: flex;
}

.cc-layout-grid-container-editmode {
  border: 1px dashed #ddd;
  border-radius: 6px;
  padding-bottom: 50vh;
  position: relative;
}

.cc-layout-grid-container-editmode:after {
  content: "Drop area";
  color: #ddd;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  position: absolute;
  bottom: 25vh;
}

.cc-layout-grid-item {
  min-height: calc(100% - var(--card-padding) * 2);
  overflow: auto;
}

.card {
  padding: var(--card-padding);
  box-shadow: var(--shadow-lg);
  border-radius: var(--card-border-radius);
  border: 1px solid var(--color-100);
  background-color: var(--color-card-bg);
  overflow: auto;
}

.cc-layout-grid-cell {
  padding: calc(var(--gutter) * .5);
  position: relative;
}

.no-page-break {
  page-break-inside: avoid;
}

.cc-layout-grid-item.no-card {
  box-shadow: unset;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  padding: 0;
  overflow: hidden;
}

.no-card .cc-layout-grid-cell {
  padding: calc(var(--gutter) / 2);
}

.dragndrop-element {
  position: relative;
}

.cd-dragndrop-container-editmode > .dragndrop-element-draggable {
  padding: 5px 5px 5px 25px;
  position: relative;
}

.cc-layout-grid-container-editmode > .dragndrop-element-draggable {
  position: relative;
}

.cd-dragndrop-container-editmode > .dragndrop-element-draggable > .drag-handle {
  cursor: move;
  background-image: url("https://s2.svgbox.net/materialui.svg?ic=drag_indicator&color=ddd");
  width: 20px;
  height: 25px;
  display: block;
  position: absolute;
  top: 9px;
  left: 0;
}

.cc-layout-grid-container-editmode > .dragndrop-element-draggable > .drag-handle {
  cursor: move;
  background-image: url("https://s2.svgbox.net/materialui.svg?ic=drag_indicator&color=ddd");
  width: 20px;
  height: 25px;
  display: block;
  position: absolute;
  top: 13px;
  left: calc(50% - 10px);
  transform: rotate(90deg);
}

.dragndrop-element-draggable:hover {
  cursor: pointer;
}

.dragndrop-element-controls {
  cursor: pointer;
  border-bottom-left-radius: 6px;
  padding: 5px;
  position: absolute;
  top: 12px;
  right: 15px;
}

.dragndrop-element-controls > div {
  background-image: url("https://s2.svgbox.net/hero-solid.svg?ic=x&color=ddd");
  background-size: 18px;
  width: 15px;
  height: 15px;
}

.dragndrop-element-draggable:hover > .dragndrop-element-controls {
  display: block;
}

.w12 {
  flex-grow: 12;
  min-width: 100%;
  max-width: 100%;
}

.w11 {
  flex-grow: 11;
  min-width: 91.6667%;
  max-width: 91.6667%;
}

.w10 {
  flex-grow: 10;
  min-width: 83.3333%;
  max-width: 83.3333%;
}

.w9 {
  flex-grow: 9;
  min-width: 75%;
  max-width: 75%;
}

.w8 {
  flex-grow: 8;
  min-width: 66.6667%;
  max-width: 66.6667%;
}

.w7 {
  flex-grow: 7;
  min-width: 58.3333%;
  max-width: 58.3333%;
}

.w6 {
  flex-grow: 6;
  min-width: 50%;
  max-width: 50%;
}

.w5 {
  flex-grow: 5;
  min-width: 41.6667%;
  max-width: 41.6667%;
}

.w4 {
  flex-grow: 4;
  min-width: 33.3333%;
  max-width: 33.3333%;
}

.w3 {
  flex-grow: 6;
  min-width: 25%;
  max-width: 25%;
}

.w2 {
  flex-grow: 2;
  min-width: 16.6667%;
  max-width: 16.6667%;
}

.w1 {
  flex-grow: 1;
  min-width: 8.33333%;
  max-width: 8.33333%;
}

.dragging {
  border: 2px dashed #2196f3;
}

.dropzone {
  border: 2px solid #2196f3;
}

.dragndrop-element-draggable, .dragndrop-element-draggable {
  cursor: move;
}

@media only screen and (width <= 600px) {
  .w5, .w6, .w7, .w8, .w9, .w10, .w11, .w12 {
    max-width: 100%;
  }

  .w3, .w4 {
    max-width: 50%;
  }

  .w1, .w2 {
    max-width: 33.3%;
  }
}

@media only screen and (width <= 1200px) {
  .w1, .w2, .w3, .w4, .w5, .w6, .w7, .w8, .w9, .w10, .w11, .w12 {
    max-width: 100%;
  }
}

.cc-popout {
  cursor: pointer;
  align-items: center;
  gap: 5px;
  display: flex;
  position: relative;
}

.cc-popout-label {
  display: inline;
}

.cc-popout-popout {
  box-shadow: var(--shadow-lg);
  z-index: 6;
  transform-origin: 0 0;
  opacity: 0;
  background-color: #fff;
  flex-direction: column;
  min-width: 100%;
  transition: all .2s;
  display: flex;
  position: absolute;
  top: 1.5rem;
  right: 0;
  transform: scale(1, 0);
}

.cc-popout-show {
  opacity: 1;
  transform: scale(1);
}

.cc-popout-icon-left, .cc-popout-icon-right {
  height: 1.2rem;
}

.cc-popout-item {
  padding: 0;
}

.c-input-wrap {
  position: relative;
}

.c-input-afterlabel {
  display: none;
  position: absolute;
}

.c-input-validation-message, .cc-tokenfield-validation-message {
  color: #ff7c7c;
  order: 2;
  padding-top: 3px;
  font-size: 11px;
}

.c-input-postfix {
  background-color: #eee;
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.c-input-postfix-input {
  padding-right: 40px;
}

.c-input-prefix {
  z-index: 2;
  background-color: #eee;
  border-radius: 5px;
  padding: 5px;
  font-weight: bold;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.c-input-prefix-input {
  padding-left: 25px;
}

.c-multiselect {
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-down-bold");
  background-position: right 5px center;
  background-repeat: no-repeat;
}

.c-multiselect-open {
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-up-bold");
}

.c-multiselect-all {
  border-bottom: 1px solid #f0f0f0;
}

.c-multiselect-all:hover {
  background-color: #f0f0f0;
}

.c-multiselect-item {
  padding-right: 30px;
}

.c-multiselect-item-selected {
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=check&color=009ef7");
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 15px;
  font-weight: bold;
}

.c-select-open {
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-up-bold");
}

.c-select-disabled, .cc-tokenfield-disabled {
  background-color: #eee;
}

.select-items {
  background-color: #fff;
  max-height: 300px;
  margin-top: 8px;
  overflow: auto;
}

.select-items > * {
  padding: 10px;
}

.select-items::-webkit-scrollbar {
  width: 10px;
}

.select-items::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.select-items::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

.select-items::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.c-select-item, .c-multiselect-item {
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--btn-border-radius);
}

.c-select-item:hover, .c-multiselect-item:hover {
  background-color: #f0f0f0;
}

.c-select-item-selected {
  background-color: #f0f0f0;
  font-weight: bold;
}

.c-select-item-kb-select {
  background-color: #f0f0f0;
}

.c-select-search, .c-select-search:focus-visible {
  border: 1px solid #555;
}

.c-html {
  display: block;
}

.cc-expander-input {
  opacity: 0;
  z-index: -1;
  width: 1px;
  margin: 0;
  padding: 0;
  position: absolute;
}

.cc-expander-tabs {
  overflow: hidden;
}

.cc-expander-icon {
  height: 1.2rem;
  margin-right: 1rem;
}

.cc-expander {
  width: 100%;
  overflow: hidden;
}

.cc-expander-label {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.cc-expander-label:after {
  content: "❯";
  text-align: center;
  width: 1em;
  height: 1em;
  padding-left: 5px;
  padding-right: 5px;
  font-size: .8rem;
  transition: all .3s;
}

.cc-expander-content {
  max-height: 0;
  transition: all .3s;
}

.cc-expander-close {
  cursor: pointer;
  justify-content: flex-end;
  padding: 1em;
  font-size: .75em;
  display: flex;
}

.cc-expander-input:checked + .cc-expander-label:after {
  padding-left: 10px;
  padding-right: 0;
  transform: rotate(90deg);
}

.cc-expander-input:checked ~ .cc-expander-content {
  max-height: 100vh;
}

.c-fileupload-container {
  align-items: center;
  gap: 5px;
  display: flex;
}

.c-fileupload-input {
  display: none;
}

.c-fileupload {
  cursor: pointer;
  border: 1px solid #777;
  padding: 7px;
}

.c-fileupload-button-nofile {
  display: none;
}

c-hyperlink > a {
  background-color: inherit;
}

.c-hyperlink-icon-left {
  height: 1.2rem;
}

.c-hyperlink-icon-right {
  height: 1.2rem;
  margin-left: 1rem;
}

.c-hyperlink > div {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.cci-modal-background {
  z-index: 5;
  background-color: #0006;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.cci-modal {
  border-radius: var(--card-border-radius);
  background-color: #fff;
  border: 0;
  flex-direction: column;
  max-width: 90%;
  height: auto;
  max-height: 90vh;
  margin: 10vh auto 5%;
  display: flex;
  position: relative;
}

cci-modal.child-hidden {
  display: block !important;
}

.cci-modal-extra-narrow, .cci-modal-small {
  width: min(20rem, 100%);
}

.cci-modal-narrow {
  width: min(30rem, 100%);
}

.cci-modal-normal {
  width: min(48rem, 100%);
}

.cci-modal-wide, .cci-modal-large {
  width: min(75rem, 100%);
}

.cci-modal-extra-wide {
  width: 100%;
}

.cci-modal-animate {
  animation-name: cci-modal-animatetop;
  -webkit-animation-duration: .4s;
  animation-duration: .37s;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

@keyframes cci-modal-animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

.cci-modal-close {
  float: right;
  color: var(--body-text-color);
  margin-top: -.7rem;
  font-size: 2rem;
}

.cci-modal-close:hover, .cci-modal-close:focus {
  cursor: pointer;
  text-decoration: none;
}

.cci-modal-header {
  padding: 1rem;
  font-size: 1.2rem;
}

.cci-modal-body {
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  max-height: 65vh;
  padding: 1rem;
  display: flex;
  overflow: auto;
}

.cci-modal-footer {
  justify-content: flex-end;
  padding: 1rem;
  display: flex;
}

.cci-modal-buttons {
  gap: .5rem;
  display: flex;
}

.gutter {
  background-color: #e3e3e3;
  background-position: 50%;
  background-repeat: no-repeat;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
}

.gutter.gutter-vertical {
  cursor: row-resize;
}

.split {
  box-sizing: border-box;
}

.split, .gutter.gutter-horizontal {
  float: left;
}

.split {
  overflow: hidden auto;
}

.cd-tree > div {
  margin-left: -1em;
}

.c-tree {
  padding-left: 0;
}

.c-tree-submenu-heading {
  cursor: pointer;
}

ul.c-tree {
  list-style: none;
}

ul.c-tree-submenu-items {
  border-left: 1px dashed #f5f5f5;
  margin-top: 0;
  list-style: none;
}

ul.c-tree-submenu-items:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 14px;
}

ul.c-tree-submenu-items:not(:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi))) {
  padding-left: 14px;
}

ul.c-tree-submenu-items:-webkit-any(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 14px;
}

ul.c-tree-submenu-items:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  padding-right: 14px;
}

.c-tree-expander-expanded {
  background-color: #fff;
  background-image: url("https://s2.svgbox.net/hero-solid.svg?ic=chevron-down&color=ccc");
  background-position: center;
  background-size: 22px;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -22px;
}

.c-tree-expander-collapsed {
  background-color: #fff;
  background-image: url("https://s2.svgbox.net/hero-solid.svg?ic=chevron-right&color=ccc");
  background-position: center;
  background-size: 22px;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -22px;
}

.c-tree-expander {
  cursor: pointer;
}

.c-tree-node {
  align-items: center;
  display: flex;
  position: relative;
}

.c-tree-node > cc-flexbox {
  flex-grow: 1;
}

.cd-table {
  width: 100%;
}

.cd-table-search-container, .cd-tree-search-container {
  align-self: flex-end;
  display: inline-block;
  position: relative;
}

.cd-tree-search-container {
  margin-bottom: 10px;
}

.cd-table-search-active {
  border: 3px solid #9500ff;
}

.cd-table-search-close, .cd-tree-search-close {
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=x-circle&color=ccc");
  width: 20px;
  height: 20px;
  display: none;
  position: absolute;
  top: 11px;
  right: 8px;
}

.cd-table-search-container:before, .cd-tree-search-container:before {
  content: url("https://s2.svgbox.net/hero-outline.svg?ic=search&color=ccc");
  color: var(--color-400);
  z-index: 1;
  width: 20px;
  height: 20px;
  font-weight: normal;
  position: absolute;
  top: 12px;
  left: 9px;
}

.empty-state {
  text-align: center;
  color: var(--color-400);
}

.cd-table.loading {
  padding: 40px;
}

@keyframes pulse {
  0% {
    background-color: #efeff4;
  }

  50% {
    background-color: #f6f6fb;
  }

  100% {
    background-color: #efeff4;
  }
}

.html-skeleton-loader {
  color: #0000;
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  border-radius: 4px;
}

.html-skeleton-block {
  vertical-align: middle;
  background-color: #f0f0f5;
  border-radius: 4px;
  height: 1em;
  animation: 1.5s ease-in-out infinite pulse;
  display: inline-block;
}

.cd-table-skeleton th {
  padding: 7px;
  padding-right: 10px !important;
}

.cd-table-skeleton td {
  padding: 7px 10px;
}

.cd-table-skeleton div {
  background-color: #f0f0f5;
  border-radius: 6px;
  width: 100%;
  height: 20px;
  position: relative;
  overflow: hidden;
}

.cd-table-search-skeleton {
  background-color: #f0f0f5;
  border: 0 !important;
}

.cd-table-skeleton div:after {
  content: "";
  background-image: linear-gradient(90deg, #fff0 0%, #ffffff80 50%, #fff0 100%);
  animation: 1.5s infinite shimmer;
  position: absolute;
  inset: 0;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.cd-tree-search-close {
  margin-top: 10px;
  right: 9px;
}

.cd-table-header {
  flex-direction: row-reverse;
  justify-content: space-between;
  display: flex;
}

.cd-table-header > div {
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  display: flex;
}

.cd-table-header > div > div {
  gap: 10px;
  display: flex;
}

.cd-table-header-left > .cd-table-header > div {
  justify-content: space-between;
}

.cd-table-nosearch > .cd-table-header {
  flex-direction: row;
}

.cd-table-footer {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: .5rem;
  display: flex;
}

.cd-table-th {
  text-align: left;
  padding: var(--table-cell-padding);
  border-bottom: 1px solid #ddd;
}

.cd-table-th-num .cd-table-col {
  text-align: right;
  justify-content: flex-end;
}

.cd-table-th-num .cd-table-sort-none {
  padding-right: 0;
}

.cd-table-td {
  padding: var(--table-cell-padding);
  height: 38px;
}

.cd-table-td-center {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.cd-table-td-right {
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.cd-table-td-num {
  text-align: right;
  white-space: nowrap;
}

.cd-table-col {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.cd-table-th-center > .cd-table-col {
  text-align: center;
  justify-content: center;
}

.cd-table-th-right > .cd-table-col {
  justify-content: flex-end;
}

.cd-table-th-right > .cd-table-col > div {
  text-align: right;
}

.cd-table-th-sort {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.cd-table-sort-none {
  background-image: url("https://s2.svgbox.net/materialui.svg?ic=sort&color=ccc");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.cd-table-sort-Ascending {
  background-image: url("https://s2.svgbox.net/hero-solid.svg?ic=sort-descending&color=ccc");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.cd-table-sort-Descending {
  background-image: url("https://s2.svgbox.net/hero-solid.svg?ic=sort-ascending&color=ccc");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px 10px;
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.cd-table-total {
  text-align: right;
  padding: var(--table-cell-padding);
  border-bottom: 1px solid #ddd;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
}

.cd-table-nototal {
  border-bottom: 1px solid #ddd;
}

.cd-table-r {
  display: grid;
}

.cd-table-r-th-num {
  text-align: right;
  justify-content: end;
  display: flex;
}

.cd-table-r-td {
  word-break: normal;
  padding: 0 20px 0 0;
  padding: var(--table-cell-padding);
  min-height: 30px;
}

.cd-table-r-td-last {
  padding-right: 0;
}

.cd-table-r-tr {
  border-bottom: 1px dashed #ddd;
}

.cd-table-r-cell-stack {
  flex-flow: column;
  display: flex;
}

.cd-table-rtd-num {
  text-align: right;
  justify-content: end;
}

.cd-table-r-cell-heading {
  color: #a1a5b7;
  font-size: 10px;
  display: block;
}

.cd-table-r-row0 {
  padding-top: 10px;
}

.c-textarea {
  width: 100%;
  font-family: inherit;
  display: block;
}

.hidden {
  display: none !important;
}

.child-hidden {
  display: none;
  position: absolute;
}

.loading {
  visibility: hidden;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  padding: 10px;
  display: flex;
  position: relative;
}

.loading:after {
  content: " ";
  visibility: visible;
  background-image: url("https://s2.svgbox.net/loaders.svg?ic=slow-spinner&color=aaa");
  background-position: center;
  background-repeat: no-repeat;
  padding: 16px;
  position: absolute;
}

.no-loader .loading:after {
  visibility: hidden;
}

.chart-loading {
  visibility: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 250px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chart-loading:after {
  visibility: visible;
  content: " ";
  visibility: visible;
  background-image: url("chart-skeleton.520be124.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 250px;
  width: 100%;
  min-height: 250px;
  padding: 50px;
  position: absolute;
}

.width-100 {
  width: 100%;
}

.loader {
  color: #0000;
  -webkit-appearance: none;
  appearance: none;
  border-radius: var(--card-border-radius);
  background-color: #0000;
}

.loader-loading {
  vertical-align: middle;
  border-radius: var(--card-border-radius);
  margin-top: var(--gutter);
  background-color: #f0f0f5;
  height: 10em;
  animation: 1.5s ease-in-out infinite pulse;
  display: inline-block;
}

.btn-loader-loading {
  background-color: #f0f0f5;
  animation: 1.5s ease-in-out infinite pulse;
  color: #0000 !important;
}

.cc-tokenfield-drop {
  padding: 10px;
}

.cc-tokenfield-token {
  border-radius: var(--form-element-border-radius);
  background-color: #ddd;
  margin: -2px 5px -2px 0;
  padding: 0 25px 2px 7px;
  display: inline-block;
  position: relative;
}

.cc-tokenfield-token-close {
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=x&color=777");
  width: 15px;
  height: 15px;
  margin-left: 5px;
  display: inline-block;
  position: absolute;
  top: 2px;
}

.cc-tokenfield-edit-entry {
  gap: 10px;
  display: flex;
}

.cc-tokenfield-edit-entry > cc-flexbox {
  flex-grow: 1;
}

.popover {
  box-shadow: var(--shadow-lg);
  z-index: 6;
  transform-origin: 0 0;
  opacity: 0;
  border-radius: var(--form-element-border-radius);
  background-color: #fff;
  flex-direction: column;
  padding: 8px;
  display: flex;
  position: absolute;
  transform: scale(1, 0);
}

.popover_show {
  opacity: 1;
  transition: all .2s;
  transform: scale(1);
}

.cb_combo_input_arrows {
  display: flex;
}

.cb_combo_input_up, .cb_combo_input_down {
  text-align: center;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px;
  width: 100%;
  height: 25px;
  display: inline-block;
  position: relative;
}

.cb_combo_input_up {
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-up-bold");
}

.cb_combo_input_down {
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-down-bold");
}

.cb_combo_container_datepicker {
  font-size: small;
}

.cb_combo_inputs_container_datepicker {
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.cb_combo_input_container {
  flex-direction: column;
  align-items: center;
  padding: 8px;
  display: flex;
}

.cb_combo_lists_datepicker {
  flex-flow: column wrap;
  align-items: center;
  gap: 10px;
  display: flex;
}

.cb_combo_input_label {
  display: block;
}

.cb_combo_input {
  text-align: center;
  -moz-appearance: textfield;
  width: 67px;
  display: block;
}

.cb_combo_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.cb_combo_list_container {
  align-items: flex-start;
  gap: 10px;
  padding: 15px 0 0;
  display: flex;
}

.cb_combo_table {
  border-spacing: 0;
  border-collapse: collapse;
  border: 0;
  margin: 0;
}

.cb_combo_table th, .cb_combo_table td {
  text-align: center;
  border: 0;
}

.cb_combo_item_Year {
  padding: 0;
  line-height: 12px;
}

.cb_combo_item_Month {
  padding: 3px;
}

.cb_combo_item_Day, .cb_combo_item_Hour, .cb_combo_item_Minute {
  padding: 3px 4px;
}

.cb_combo_item_selected {
  background-color: #ddd;
  border-radius: 20px;
  padding: 5px;
  font-weight: bold;
}

.c-datetime-drop-inner, .c-daterange-presets-container {
  display: flex;
}

.c-datetime-presets-container {
  flex-direction: column;
  display: flex;
}

.c-form-sub {
  border: 1px solid #ddd;
  border-radius: .2rem;
  margin-bottom: 1em;
  padding: 1em;
}

.c-form-sub-heading {
  font-weight: bold;
  line-height: 1.25rem;
}

.c-editor-code-container {
  width: 100%;
  min-width: 500px;
  height: 400px;
  display: inline-block;
  position: relative;
  overflow: auto;
}

.c-editor-code {
  font-size: 12px;
}

c-editor-richtext {
  width: 100%;
}

.c-switch-button {
  white-space: nowrap;
}

.cc-resizable-pane {
  float: left;
  overflow: auto;
}

cc-switchpanel {
  width: 100%;
}

.cc-switchpanel-tabheader {
  background-color: #f1f1f1;
  display: flex;
  overflow: hidden;
}

.cc-switchpanel-tab {
  background-color: inherit;
  float: left;
  cursor: pointer;
  border: none;
  outline: none;
  margin: 5px 0 0 5px;
  padding: 12px 16px;
  transition: all .3s;
}

.cc-switchpanel-tab-active {
  background-color: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

input[type="radio"] {
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-200);
  border-radius: 50%;
  outline: none;
  width: 20px;
  height: 20px;
  margin: 6px 6px -4px;
  padding: 0;
  transition: outline .1s;
}

input[type="radio"]:not(:disabled):checked {
  border: 6px solid var(--brand-color);
  background-color: #fff;
  background-clip: content-box;
  padding: 2px;
}

input[type="radio"]:disabled {
  background-color: var(--color-200);
}

input[type="radio"]:disabled:checked {
  border: 6px solid var(--color-300);
}

.cc-switchpanel-radio-container {
  cursor: pointer;
}

.cc-switchpanel-radio-content {
  padding: 5px 25px;
}

.cb_combo_input_container {
  padding: 0 8px 8px;
}

.cb_dtp_day {
  text-align: center;
  cursor: pointer;
  padding: 6px;
}

.cb_dtp_day_selected {
  background-color: var(--brand-color);
  border-radius: var(--btn-border-radius);
  color: #fff;
}

.cb_dtp_btn {
  cursor: pointer;
  background-color: #fff;
  border: 0;
}

.cb_dtp_btn > span {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 13px;
  height: 15px;
  display: block;
}

.cb_dtp_year_down > span {
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=chevron-double-left");
}

.cb_dtp_month_down > span {
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=chevron-left");
}

.cb_dtp_year_up > span {
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=chevron-double-right");
}

.cb_dtp_month_up > span {
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=chevron-right");
}

.cb_dtp_yearmonth {
  align-items: center;
  gap: 2px;
  margin-bottom: 10px;
  display: flex;
}

.cb_dtp_yearmonth > select, .cb_dtp_time_container > select {
  border-radius: var(--form-element-border-radius);
  border: 1px solid var(--form-element-color);
  padding: 5px;
}

.cb_dtp_days {
  margin: 0 auto;
}

.cb_dtp_time_container {
  justify-content: center;
  align-items: center;
  gap: 3px;
  margin: 5px 0;
  font-size: 20px;
  display: flex;
}

.cbp_time_icon {
  background-image: url("https://s2.svgbox.net/weather-icons.svg?ic=time-2&color=777");
  width: 20px;
  height: 20px;
}

.cc-switchpanel-wizard-header {
  text-align: center;
  font-size: 20px;
}

.cc-switchpanel-wizard-back {
  padding: 5px 20px 5px 5px;
  font-size: 20px;
}

.cc-switchpanel-tab-content {
  background-color: #fff;
  padding: 10px 5px;
  overflow: auto;
}

.c-notifier-base {
  opacity: 1;
  z-index: 7;
  width: max(50vw, min(500px, 100vw));
  transition: all .2s;
  position: fixed;
  top: 0;
  left: max(0px, min(50vw - 250px, 25vw));
  transform: scale(1, 0);
}

.c-notifier-container {
  box-shadow: var(--shadow-lg);
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  padding: .625rem;
  position: relative;
}

.c-notifier-pin:after {
  content: "";
  content: "";
  vertical-align: middle;
  background-color: #ddd;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg transform='rotate(45, 12, 12)'%3E%3Cpath fill='none' stroke='black' stroke-width='1.5' d='M16 2H8C7.45 2 7 2.45 7 3C7 3.55 7.45 4 8 4H9V9.58L4.71 13.88C4.26 14.32 4 14.91 4 15.54V17C4 17.55 4.45 18 5 18H11V21C11 21.55 11.45 22 12 22C12.55 22 13 21.55 13 21V18H19C19.55 18 20 17.55 20 17V15.54C20 14.91 19.74 14.32 19.29 13.88L15 9.58V4H16C16.55 4 17 3.55 17 3C17 2.45 16.55 2 16 2Z'/%3E%3C/g%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg transform='rotate(45, 12, 12)'%3E%3Cpath fill='none' stroke='black' stroke-width='1.5' d='M16 2H8C7.45 2 7 2.45 7 3C7 3.55 7.45 4 8 4H9V9.58L4.71 13.88C4.26 14.32 4 14.91 4 15.54V17C4 17.55 4.45 18 5 18H11V21C11 21.55 11.45 22 12 22C12.55 22 13 21.55 13 21V18H19C19.55 18 20 17.55 20 17V15.54C20 14.91 19.74 14.32 19.29 13.88L15 9.58V4H16C16.55 4 17 3.55 17 3C17 2.45 16.55 2 16 2Z'/%3E%3C/g%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.c-notifier-pinned:after {
  content: "";
  content: "";
  vertical-align: middle;
  background-color: #ddd;
  width: 15px;
  height: 15px;
  margin-left: 5px;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='11' fill='none' stroke='black' stroke-width='1.5'/%3E%3Cg transform='rotate(45, 12, 12)'%3E%3Cpath fill='none' stroke='black' stroke-width='1.5' d='M16 2H8C7.45 2 7 2.45 7 3C7 3.55 7.45 4 8 4H9V9.58L4.71 13.88C4.26 14.32 4 14.91 4 15.54V17C4 17.55 4.45 18 5 18H11V21C11 21.55 11.45 22 12 22C12.55 22 13 21.55 13 21V18H19C19.55 18 20 17.55 20 17V15.54C20 14.91 19.74 14.32 19.29 13.88L15 9.58V4H16C16.55 4 17 3.55 17 3C17 2.45 16.55 2 16 2Z'/%3E%3C/g%3E%3Cline x1='4' y1='4' x2='20' y2='20' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Ccircle cx='12' cy='12' r='11' fill='none' stroke='black' stroke-width='1.5'/%3E%3Cg transform='rotate(45, 12, 12)'%3E%3Cpath fill='none' stroke='black' stroke-width='1.5' d='M16 2H8C7.45 2 7 2.45 7 3C7 3.55 7.45 4 8 4H9V9.58L4.71 13.88C4.26 14.32 4 14.91 4 15.54V17C4 17.55 4.45 18 5 18H11V21C11 21.55 11.45 22 12 22C12.55 22 13 21.55 13 21V18H19C19.55 18 20 17.55 20 17V15.54C20 14.91 19.74 14.32 19.29 13.88L15 9.58V4H16C16.55 4 17 3.55 17 3C17 2.45 16.55 2 16 2Z'/%3E%3C/g%3E%3Cline x1='4' y1='4' x2='20' y2='20' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.c-notifier-container-show {
  opacity: 1;
  transform-origin: 0 0;
  transform: scale(1);
}

.c-notifier-notification {
  cursor: pointer;
  background-position: 5px 7px;
  background-repeat: no-repeat;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 10px 35px;
  display: flex;
}

.c-notifier-success {
  color: var(--color-success);
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=check-circle&color=50CD89");
}

.c-notifier-info {
  color: var(--color-info);
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=check-circle&color=3e97ff");
}

.c-notifier-error {
  color: var(--color-error);
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=x-circle&color=F1416C");
}

.c-notifier-alert {
  color: var(--color-warning);
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=alert&color=ffbb00");
}

.hilight {
  background-color: #529e85 !important;
}

.c-brick-linker-container {
  overflow: auto;
}

.c-autocomplete-container {
  position: relative;
}

.c-autocomplete-input {
  cursor: text;
  padding-right: 30px;
  font-weight: normal;
}

.c-autocomplete-disabled {
  background-color: #eee;
  min-height: 38px;
}

.c-autocomplete-input:empty:before {
  content: attr(placeholder);
  color: #ddd;
  margin-left: 3px;
}

.c-autocomplete-input:empty:after {
  content: url("https://s2.svgbox.net/hero-outline.svg?ic=search&color=ddd");
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
}

.c-autocomplete-loading .c-autocomplete-input {
  padding-right: 50px;
}

.c-autocomplete-loading:after {
  content: url("https://s2.svgbox.net/loaders.svg?ic=slow-spinner&color=ddd");
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 30px;
}

.c-autocomplete-x {
  cursor: pointer;
  background-image: url("https://s2.svgbox.net/hero-outline.svg?ic=x&color=ddd");
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.c-autocomplete-group {
  font-size: var(--text-sm);
  color: var(--color-400);
  padding-bottom: 0;
}

.c-autocomplete-group:first-child {
  padding-top: 0;
}

.c-autocomplete-group:not(:first-child) {
  border-top: 1px solid var(--color-100);
}

.c-daterange-popout {
  z-index: 6;
  box-shadow: var(--shadow-lg);
  background-color: #fff;
  flex-direction: row;
  padding: 10px;
  display: none;
  position: absolute;
}

.c-daterange-popout input, .c-daterange-popout select {
  border: 1px solid #ddd;
  border-radius: .2rem;
  padding: 8px;
}

.c-daterange-drop-inner {
  width: 467px;
}

.the-datepicker__main {
  border: 0;
}

.c-daterange-presets {
  flex-direction: column;
  padding-left: 10px;
  display: flex;
}

.c-daterange-presets-container {
  flex-flow: wrap;
  display: flex;
}

.c-datetime-presets-container {
  flex-wrap: wrap;
  display: flex;
}

.c-daterange-selection {
  text-align: center;
}

.c-daterange-presets button {
  margin: 0 0 3px;
}

.c-daterange-h {
  text-align: center;
}

.c-daterange-fromto {
  display: flex;
}

.cd-pivot-totable-title {
  font-size: 1.2rem;
}

.cd-pivot-table-search {
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: .2rem;
  margin-bottom: 10px;
  padding: 8px;
}

.cd-pivot-table-header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 2.5rem;
  padding: .5rem;
  display: flex;
}

.cd-pivot-table-footer {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding: .5rem;
  display: flex;
}

cd-pivot-table table {
  border-collapse: collapse;
  width: 100%;
}

cd-pivot-table tr th {
  text-align: left;
  vertical-align: bottom;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 0;
}

cd-pivot-table tr td {
  border-top: 1px solid #ddd;
  height: 38px;
  margin: 0;
  padding: 5px;
}

cd-pivot-table tr .pt-empty {
  border-top: 0;
}

.cd-pivot-table-drill {
  color: #fff;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 0 1px;
  display: flex;
}

.cd-pivot-table-drill-closed:before {
  content: url("https://s2.svgbox.net/octicons.svg?ic=chevron-down-bold&color=57575b");
}

.cd-pivot-table-drill-open:before {
  content: url("https://s2.svgbox.net/octicons.svg?ic=chevron-right-bold&color=57575b");
}

.pt-sum {
  text-align: right;
  font-weight: bold;
}

.pt-continue {
  color: var(--color-light-3);
}

.td-num {
  text-align: right;
}

.top-total-th {
  padding-left: 22px;
}

.viz_top_total {
  text-align: right;
  white-space: nowrap;
  padding-top: 5px;
}

.c-gauge {
  position: relative;
}

.c-gauge-center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.c-gauge-layout {
  display: flex;
}

.c-gauge-legend-row {
  justify-content: space-between;
  display: flex;
}

.c-gauge-legend-color {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  margin-right: 7px;
}

.c-gauge-legend-label {
  align-items: center;
  display: flex;
}

.c-template-form-any-heading {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.c-template-form-any-heading select {
  border: 1px solid #ddd;
  border-bottom: 0 solid #fff;
  border-radius: .2rem .2rem 0 0;
  margin-left: 5px;
  padding: 5px;
}

.c-template-form-any-heading span {
  font-weight: bold;
}

.c-template-form-textarea {
  margin-bottom: 10px;
}

.c-template-form-desc {
  color: #777;
  margin-bottom: 1em;
  font-size: 11px;
}

.c-template-form-button {
  color: #fff;
  background-color: #02366d;
  border: 0;
  border-radius: 4px;
  padding: 7px;
}

.c-template-form-button-sec {
  color: #fff;
  background-color: #fff;
  border: 0;
  border-radius: 4px;
  padding: 7px;
}

.cc-flexbox-padded {
  padding: var(--card-padding);
  gap: var(--gutter);
}

.editor-sidebar {
  background-color: #f1f1f1;
  max-width: 500px;
  height: calc(100vh - 30px);
  padding: 10px;
  overflow: auto;
}

.editor-sidebar .c-switch-button {
  padding: 10px;
}

.editor-visual-brick {
  border: 1px solid #5eadf2;
  border-radius: 5px;
  margin: 5px;
  display: block;
  position: relative;
}

.editor-visual-brick .hidden {
  opacity: .25;
  display: block !important;
}

.editor-visual-brick-tab {
  color: #fff;
  cursor: pointer;
  background-color: #5eadf2;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px 5px;
  font-size: 12px;
  display: inline-block;
  position: relative;
  top: 0;
  left: 0;
}

.editor-visual-brick-tab > div {
  display: inline-block;
}

.editor-visual-brick-tab > div > img {
  height: 13px;
}

.editor-visual-brick-tab > div:not(:first-child) {
  color: #5eadf2;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 5px;
  padding: 0;
  font-weight: bold;
  line-height: 10px;
  display: none;
}

.editor-visual-brick-tab:hover > div:not(:first-child) {
  display: inline-block;
}

.editor-unhide-btns {
  border: 1px solid #ccc;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  position: fixed;
  top: 50%;
  left: 0;
}

.editor-unhide-btns .c-button {
  color: #ccc;
  background-color: #ffffff80;
  border: 0;
  width: 25px;
  padding: 5px;
}

.editor-unhide-btns > div {
  border-top: 1px solid #ccc;
}

.editor-unhide-btns > div:first-child {
  border-top: 0;
}

.editor-composition-container {
  height: 100vh;
  overflow: auto;
}

.editor-small-card {
  background-color: #fff;
  border-radius: 5px;
  margin: 5px 0;
  padding: 5px;
}

.editor-context-menu {
  box-shadow: var(--shadow-md);
  background-color: #fff;
  border: 1px solid #eee;
}

.editor-context-menu button {
  color: #555;
  background-color: #fff;
  border: 0;
  padding: 5px 10px;
  font-weight: normal;
}

.editor-drop-outline {
  position: relative;
}

.editor-drop-outline > * {
  border: 1px dashed #eee;
}

.editor-drop-outline > :before {
  content: attr(data-before);
  color: #fff;
  background-color: #5eadf2;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px 5px;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

.editor-modal .cci-modal {
  max-width: 1340px;
}

.editor-modal .cci-modal-body {
  padding: 10px;
}

.editor-form {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.editor-form-property {
  border: 1px solid #ddd;
  border-radius: 6px;
  flex-direction: column;
  min-width: 120px;
  padding: 10px;
  display: flex;
}

.editor-form-property-header {
  color: #1b2037;
  justify-content: space-between;
  gap: 3px;
  display: flex;
}

.editor-property-title {
  text-wrap: nowrap;
  gap: 5px;
  display: flex;
}

.editor-propname-input {
  color: #1b2037;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
}

.editor-form-property-desc {
  color: #777;
  width: 0;
  min-width: 100%;
  font-size: 11px;
}

.editor-form-properties {
  flex-flow: wrap;
  gap: 5px;
  display: flex;
}

.editor-form-properties > div {
  padding-top: 5px;
}

.editor-drag-handle {
  cursor: grab;
  background-image: url("https://s2.svgbox.net/materialui.svg?ic=drag_indicator&color=777");
  background-size: 23px;
  width: 18px;
  height: 26px;
  margin-top: -7px;
  transform: rotate(90deg);
}

.editor-drag-handle:active {
  cursor: grabbing;
}

.editor-drop-target {
  border: 1px dashed #777;
}

.editor-color-1 {
  background-color: #fff;
}

.editor-color-2 {
  background-color: #e6f0fb;
}

.editor-value-editor {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.editor-tag-pill {
  color: #fff;
  background-color: #0071eb;
  border-radius: 10px;
  margin: 0 2px;
  padding: 3px 6px;
}

.editor-tag-editor-container {
  width: 100%;
}

.editor-tag-ui, .editor-outnodes, .editor-term {
  border: 1px solid #ddd;
  border-radius: 6px;
  flex-direction: column;
  padding: 10px;
  display: flex;
}

.editor-outnodes {
  border: 1px solid #fff;
  padding-top: 0;
}

.editor-term {
  background-color: #e6f0fb;
  border: 1px solid #0071eb;
  align-items: flex-start;
  display: flex;
}

.editor-no-outnodes {
  font-size: 11px;
  font-style: italic;
}

.editor-outnode-container {
  margin-left: 10px;
}

.editor-node-button {
  color: #0071eb;
  cursor: pointer;
  background-color: #0000;
  border: 1px solid #0071eb;
  border-radius: 6px;
  margin-bottom: 1px;
  padding: 1px 4px;
}

.editor-node-button-selected {
  color: #fff;
  background-color: #0071eb;
}

.editor-condition {
  color: #0071eb;
}

.editor-section {
  background-color: #edfdf6;
  border: 1px solid #00b164;
}

.editor-alternatively {
  color: #00b164;
  padding: 10px 0;
}

.editor-node-exp {
  background-color: #0000;
  border: 0;
  width: 10px;
  margin-left: -10px;
  padding-left: 0;
}

.editor-section-del_btn {
  cursor: pointer;
  background-color: #0000;
  border: 0;
  align-self: flex-end;
  margin: 0;
  padding: 0;
  font-size: 22px;
  line-height: 12px;
}

.editor-value-editor .cm-tooltip-autocomplete .cm-completion-section {
  flex-wrap: wrap;
  display: flex;
}

.cbeditor-label {
  color: #3f4254;
  text-align: left;
  padding: 9px 0;
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.cbeditor-select, .cbeditor-input {
  height: auto !important;
  padding: 8px !important;
}

.cbeditor-select, .editor-any-type-select {
  color: #3f4254;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.editor-prop-add-input {
  color: #3f4254;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 6px;
}

.editor-value-type-select, .editor-comparator > select {
  color: #3f4254;
  text-align: left;
  background-color: #0000;
  border: 0;
  font-size: 14px;
  font-weight: 600;
}

.editor-comparator {
  color: #0071eb;
  align-items: center;
  gap: 5px;
  display: flex;
}

.editor-comparator > select {
  color: #0071eb;
  text-transform: uppercase;
}

.editor-tag-ui .c-select-container {
  display: block !important;
}

#cb_0_composition_switchpanel .cci-modal-background {
  width: calc(100% - 500px);
  margin-left: 500px;
}

.c-vd-brick, .c-vd-in, .cvd-ti {
  gap: 5px;
  display: flex;
}

.c-vd-brick-name {
  cursor: pointer;
  font-weight: bold;
}

.c-vd-type {
  color: #ccc;
  font-size: 12px;
}

.c-vd-btn {
  color: #ccc;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  align-items: center;
  font-size: 12px;
  display: inline-flex;
}

.c-vd-btn:before {
  content: url("https://s2.svgbox.net/hero-outline.svg?ic=eye&color=02366d");
  width: 13px;
  height: 13px;
  margin-right: 3px;
  display: inline-block;
}

.c-vd-btn-tag {
  color: #ccc;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  align-items: center;
  font-size: 12px;
  display: inline-flex;
}

.c-vd-btn-tag img {
  width: 13px;
}

.c-vd-btn-active {
  color: #00b164;
  border: 1px solid #00b164;
}

.cvd-term {
  cursor: pointer;
  text-decoration: underline;
}

.cvd-term-active {
  color: #00b164;
}

.brick-linker-container {
  height: 100vh;
  padding: 20px;
  overflow: auto;
}

.linker-brick {
  background-color: #fff;
  border: 1px solid #0071eb;
  border-radius: 6px;
  flex-direction: column;
  padding: 20px;
  display: flex;
  position: relative;
}

.linker-brick-alternate {
  background-color: #f2f9ff;
}

.linker-brick-inputs {
  display: flex;
  position: absolute;
  top: -9px;
}

.linker-brick-outputs {
  justify-content: flex-end;
  width: 100%;
  padding-right: 16px;
  display: flex;
  position: absolute;
  bottom: -9px;
}

.linker-brick-input {
  color: #fff;
  background-color: #0051a7;
  border: 2px solid #0051a7;
  border-radius: 20px;
  min-width: 16px;
  height: 16px;
  padding: 0 2px;
  font-size: 11px;
  line-height: 10px;
}

.linker-brick-output {
  color: #fff;
  background-color: #0051a7;
  border: 2px solid #0051a7;
  border-radius: 20px;
  min-width: 16px;
  height: 16px;
  margin: auto;
  padding: 0 2px;
  font-size: 11px;
  line-height: 10px;
}

.linker-row {
  cursor: pointer;
  display: flex;
}

.linker-element {
  align-items: center;
  gap: 5px;
  width: 300px;
  display: flex;
}

.linker-hline {
  background-color: #00f;
  flex-grow: 1;
  height: 1px;
}

.linker-arrow-right {
  float: right;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #02366d;
  width: 0;
  height: 0;
}

.linker-arrow-left {
  float: right;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-right: 5px solid purple;
  width: 0;
  height: 0;
}

.linker-brick-heading {
  color: #181c32;
  font-weight: 600;
}

.linker-brick-in {
  color: purple;
}

.linker-brick-out {
  color: #02366d;
}

.linker-connection {
  align-items: center;
  width: 10px;
  display: flex;
}

.linker-v {
  border-right: 1px solid #00f;
  align-items: center;
  width: 10px;
  display: flex;
}

.linker-h {
  background-color: #00f;
  flex-grow: 1;
  width: 10px;
  height: 1px;
}

.linker-u {
  border-bottom: 1px solid #00f;
  border-right: 1px solid #00f;
  border-bottom-right-radius: 10px;
  width: 10px;
  height: 10px;
}

.linker-d {
  border-top: 1px solid #00f;
  border-right: 1px solid #00f;
  border-top-right-radius: 10px;
  align-self: flex-end;
  width: 10px;
  height: 10px;
}

.linker-b {
  width: 10px;
}

.linker-brick-heading > span {
  color: #152332;
  font-size: 12px;
}

.linker-contains {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.c-button-editor {
  background-color: #fff !important;
}

.c-button-editor-tree {
  background-image: none;
  background-color: #eee !important;
  border-radius: 10px !important;
  padding: 1px !important;
}

.editor-node-container img {
  height: 16px;
}

.editor-node-container c-html {
  position: relative;
  top: -1px;
}

.non-mobile-only {
  display: revert;
}

.mobile-only {
  display: none;
}

@media only screen and (width <= 700px) {
  .non-mobile-only {
    display: none;
  }

  .mobile-only {
    display: revert;
  }

  .row {
    width: calc(100vw - var(--gutter));
  }
}

.modalize {
  z-index: 10;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.modalize > * {
  z-index: 10;
  background-color: #fff;
  position: relative;
}

.modalize:after {
  content: " ";
  z-index: 5;
  background-color: #0000004d;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
}

.tooltip {
  z-index: 6;
}

.tooltip-pop {
  z-index: 5;
  color: #fff;
  background-color: #10304b;
  border-radius: 6px;
  max-width: 400px;
  padding: 5px;
  position: absolute;
}

.tooltip-arrow {
  z-index: 5;
  border: 5px solid #0000;
  border-top-color: #3f4254;
  border-bottom: 0;
  width: 10px;
  margin-left: -5px;
  position: absolute;
}

.tooltip-top:hover:before, .tooltip-top:hover:after, .tooltip-right:hover:before, .tooltip-right:hover:after, .tooltip-bottom:hover:before, .tooltip-bottom:hover:after, .tooltip-left:hover:before, .tooltip-left:hover:after {
  visibility: visible;
  opacity: 1;
  scale: 100%;
}

.tooltip:before {
  content: attr(tooltip);
  opacity: 0;
  text-wrap: nowrap;
  color: #fff;
  z-index: 1;
  background-color: #3f4254;
  border-radius: 6px;
  padding: 5px;
  font-weight: 500;
  transition: opacity .4s;
  position: absolute;
  scale: 0%;
}

.tooltip:after {
  content: " ";
  opacity: 0;
  border-style: solid;
  border-width: 5px;
  margin-left: -5px;
  transition: opacity .4s;
  position: absolute;
  scale: 0%;
}

.tooltip-top:before {
  bottom: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-top:after {
  border-color: #3f4254 #0000 #0000;
  bottom: calc(100% - 4px);
  left: 50%;
}

.tooltip-right:before {
  left: calc(100% + 6px);
}

.tooltip-right:after {
  border-color: #0000 #3f4254 #0000 #0000;
  margin-left: -5px;
  right: -6px;
}

.tooltip-bottom:before {
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-bottom:after {
  border-color: #0000 #0000 #3f4254;
  bottom: -6px;
  left: 50%;
}

.tooltip-left:before {
  right: calc(100% + 6px);
}

.tooltip-left:after {
  border-color: #0000 #0000 #0000 #3f4254;
  left: -1px;
}

.cm-search.cm-panel {
  font-size: 14px;
}

.cm-panel.cm-search input {
  font-family: monospace;
  font-size: 12px;
}

.cm-panel.cm-search button {
  color: #fff;
  background-color: #006eff;
  background-image: none;
  border: 0;
  border-radius: 6px;
  padding: 5px 9px;
  font-size: 11px;
}

.c-button-icon-right {
  height: 1rem;
  margin-bottom: -.2rem;
  margin-left: .2rem;
}

.c-button-icon-left {
  height: 1rem;
  margin-bottom: -.2rem;
  margin-right: .5rem;
}

.btn-text, .c-button.btn-text, .c-button.c-button-textonly {
  color: var(--body-text-color);
  background: none;
  padding: 0;
}

.btn {
  font-family: inherit;
  font-weight: bold;
  font-size: var(--text-md);
  cursor: pointer;
  padding: var(--form-element-padding);
  border-radius: var(--btn-border-radius);
  border: 0;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
}

.btn, .c-hyperlink > div {
  gap: 9px;
}

.btn-primary, .c-hyperlink.btn-primary {
  background-color: var(--brand-color);
  color: #fff;
}

.btn-secondary {
  color: var(--body-text-color);
  background-color: #e8e8e8;
}

.c-pager {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.c-pager-button-active {
  border-radius: var(--btn-border-radius);
}

.c-pager-info {
  text-align: center;
  color: var(--color-light-1);
  text-wrap: nowrap;
  font-size: 11px;
}

.c-pager-prev {
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-left-bold&color=57575b");
  background-position: center;
  background-repeat: no-repeat;
}

.c-pager-next {
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-right-bold&color=57575b");
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
}

.c-switch-container {
  display: inline-block;
}

.c-switch-button-group, .c-pager-buttons {
  display: flex;
}

.c-switch-button, .c-pager-button, .btn-secondary.c-switch-button {
  border: 1px solid var(--form-element-color);
  border-right: 0;
  border-radius: 0;
}

.c-switch-button:first-child, .c-pager-prev {
  border-top-left-radius: var(--btn-border-radius);
  border-bottom-left-radius: var(--btn-border-radius);
}

.c-switch-button:last-child, .c-pager-next {
  border-top-right-radius: var(--btn-border-radius);
  border-bottom-right-radius: var(--btn-border-radius);
  border-right: 1px solid var(--form-element-color);
}

.c-switch-button-inactive, .c-pager-button-inactive {
  color: var(--color-light-1);
  background-color: #fff;
}

.c-pager-button {
  text-wrap: nowrap;
  border-radius: var(--btn-border-radius);
  border: 0;
}

.form {
  gap: 10px;
}

.form-element-container {
  flex-direction: column;
  gap: 5px;
  display: flex;
}

.form-element-label {
  font-weight: bold;
  display: block;
}

.form-element {
  border: 1px solid var(--form-element-color);
  padding: var(--form-element-padding);
  border-radius: var(--form-element-border-radius);
  font-size: var(--text-md);
  width: 100%;
  line-height: 1.5;
  position: relative;
}

.form-element-caption {
  color: var(--color-light-2);
  font-size: var(--text-xs);
}

.c-autocomplete-input {
  padding-right: 25px;
  font-weight: normal;
  position: relative;
}

.c-select {
  white-space: nowrap;
  background-image: url("https://s2.svgbox.net/octicons.svg?ic=chevron-down-bold");
  background-position: right 5px center;
  background-repeat: no-repeat;
  padding-right: 30px;
  display: block;
  overflow: hidden;
}

.cc-tokenfield-select {
  flex-wrap: wrap;
  padding-right: 25px;
  display: flex;
}

.cd-table-search, .cd-tree-search {
  margin-bottom: 10px;
  padding-left: 35px;
}

.c-switch-radios {
  gap: .5rem;
  display: flex;
}

.c-switch-btn-active, .c-pager-button-active, .c-switch-button:first-child.c-switch-btn-active {
  border: 1px solid var(--brand-color);
  border-right: 0;
}

.c-switch-btn-active + .c-switch-button {
  border-left: 0;
}

.c-switch-btn-active:last-child {
  border-right: 1px solid var(--brand-color);
}

.c-rangelsider-datalist {
  writing-mode: vertical-lr;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.c-rangelsider-datalist > option {
  padding: 0;
  rotate: 270deg;
}
/*# sourceMappingURL=composition.d01ebb06.css.map */
